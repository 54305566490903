
.Logo {
  width: 200px;
  filter: hue-rotate(40deg);
}

.App {
  /* background-color: maroon; */
}

.App-header {
  text-align: right;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ddd;
  padding: 0 30px;
}

.Hero-image {
  width: 400px;
  opacity: 0.7;
  margin: 0 40px;
}

.Container {
  width: 600px;
  display: block;
  text-align: center;
  /* -webkit-filter: drop-shadow(2px 2px 10px rgba(0,0,0,0.7));
  filter: drop-shadow(2px 2px 10px rgba(0,0,0,0.7)); */
}

@media only screen and (min-width: 375px) and (max-width: 969px) {
  img.Logo {
    width: 100px;
  }
  .App-header {
    text-align: right;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #ddd;
    padding: 0 30px;
  }

  .Container {
    z-index: 3;
    width: 330px;
    display: block;
    /* -webkit-filter: drop-shadow(2px 2px 10px rgba(0,0,0,0.7));
    filter: drop-shadow(2px 2px 10px gba(0,0,0,0.7)); */
  }

  .Hero-image {
    width: 350px;
    opacity: 0.7;
    margin: 0 40px;
  }

  h1 {
    font-size: 28px;
  }
}

.App-header div {
  top: -30px;
  position: relative;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
